import React, { FC } from 'react';
import StartButton from './StartNowButton';
import styled from 'styled-components';
import { DynamicImage } from 'components';
import { mobile, smMobile } from 'styles/breakpoints';
import BookGif from '../../../../assets/images/lastingChange/Book_Gif.gif';
import BookGifWL from '../../../../assets/images/lastingChange/Book_Gif_WL.gif';

interface BookReviewProps {
  title: string;
  image: string;
  buttonText: string;
  onClick: () => void;
  variant: string;
}

const BookReview: FC<BookReviewProps> = ({
  title,
  image,
  buttonText,
  onClick,
  variant,
}) => {
  const renderImg = () => {
    if (variant === 'weight-loss') {
      return BookGifWL;
    }
    return BookGif;
  };

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <BookImage alt="bookImage" src={renderImg()} />
        <Button onClick={onClick}>{buttonText}</Button>
      </Container>
    </>
  );
};

export default BookReview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    padding: 0 16px;
  }
`;

const Title = styled.h1`
  width: 560px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 120%;
  text-align: center;
  margin-top: 64px;
  @media ${mobile} {
    font-size: 1.5rem;
    width: 343px;
  }
  @media ${smMobile} {
    width: 288px;
  }
`;

const BookImage = styled.img`
  width: 370px;
  margin-top: 24px;
  @media ${smMobile} {
    width: 288px;
  }
`;

const Button = styled(StartButton)`
  background: #804da1;
  border-radius: 100px;
  width: 560px;
  margin-top: 24px;
  height: 56px;
  margin-bottom: 64px;
  @media ${mobile} {
    width: 100%;
  }
`;
