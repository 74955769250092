import { PrimaryButton, Text } from 'components';
import BMI from 'components/BMI';
import MetabolicAge from 'components/MetabolicAge';
import WeightLossChart from 'components/WeightLossChart';
import WeightLossStats from 'components/WeightLossStats';
import React, { FC } from 'react';
import styled from 'styled-components';
import LastProgramSVG from 'assets/images/weight-loss/loss-program.svg';
import PlanSVG from 'assets/images/weight-loss/sticking-plan.svg';
import { tablet } from 'styles/breakpoints';

interface WeightLossSummaryProps {
  title: string;
  onClick: () => void;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${tablet} {
    max-width: 343px;
  }
`;

const Title = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.016em;
  text-align: center;

  margin-bottom: 1rem;
  @media ${tablet} {
    text-align: left;
  }
`;

const Title2 = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: -0.016em;
  text-align: center;

  margin-top: 40px;
  margin-bottom: 1rem;
`;

const MetabolicAgeStyled = styled(MetabolicAge)`
  margin-top: 1rem;
`;

const LastProgramTitle = styled(Text)`
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;

  font-size: 1.75rem;
  line-height: 36px;

  margin: 2rem 0;
  @media ${tablet} {
    margin: 1rem 0;
    max-width: 343px;
    font-size: 18px;
    line-height: 25px;
  }
`;

const BMIStyled = styled(BMI)`
  margin-top: 1rem;
`;

const ButtonStyled = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  max-width: 560px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media ${tablet} {
    max-width: 343px;
  }
` as typeof PrimaryButton;

const PlanSVGStyled = styled(PlanSVG)`
  width: 560px;

  @media ${tablet} {
    max-width: 343px;
  }
`;

const LastProgramSVGStyled = styled(LastProgramSVG)`
  width: 560px;

  @media ${tablet} {
    max-width: 343px;
  }
`;

const WeightLossChartStyled = styled(WeightLossChart)`
  border: 1px solid #eeeeee;
  border-radius: 8px;
`;

const WeightLossSummary: FC<WeightLossSummaryProps> = ({ title, onClick }) => (
  <Container>
    <InnerContainer>
      <Title>{title}</Title>
      <WeightLossChartStyled />
      <Title2>Your personal summary:</Title2>
      <WeightLossStats />
      <MetabolicAgeStyled />
      <BMIStyled />
      <LastProgramTitle>
        Gradually build your wellness goals and achieve better results every
        month
      </LastProgramTitle>
      <LastProgramSVGStyled />
      <ButtonStyled color="deepPurple" onClick={onClick}>
        Get Lasting Change now
      </ButtonStyled>
      <PlanSVGStyled />
    </InnerContainer>
  </Container>
);

export default WeightLossSummary;
