import React, { FC, useEffect, useRef } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { Headline, LoadingPage, PrimaryButton, Reviews, Seo } from 'components';
import Header from 'components/Header';
import { mobile, useQuery } from 'styles/breakpoints';
import { useRouter } from 'apis/history';
import { Analytics } from 'apis/Analytics';
import SeparatorIcon from 'assets/icons/separator.svg';
import { useFirstVisitDate, usePageView, useQuizData } from 'utils/hooks';
import { AppState } from 'state/types';
import { Quiz as QuizType } from 'types/quiz';

import AnswerResults from './components/AnswerResults';
import Help from './components/Help';
import MembersStatistics from './components/MembersStatistics';
import Description from './components/Description';
import CheckItem from 'components/CheckItem';
import IconList from './components/IconList';
import HorizontalList from './components/HorizontalList';
import AppActions from './components/AppActions';
import Promise from './components/Promise';
import StartNowButton from './components/StartNowButton';
import AppFeatures from './components/AppFeatures';
import CaseStudies from './components/CaseStudies';
import BannerSection from 'components/BannerSection';
import SliderBenefits from './components/SliderBenefits';
import CheckoutDescription from 'pages/start/checkout/components/Description';

import 'swiper/css';
import Consultation from './components/Consultation';
import SliderGraph from './components/SliderGraph';
import Container from 'pages/start/checkout/components/Container';
import ImageSection from 'pages/start/checkout/components/ImageSection';
import 'swiper/css/pagination';
import AnswerImages from './components/AnswerImages';
import GraphAndType from './components/GraphAndType';
import { ContentLoader } from 'components/others/ContentLoader';
import { searchForStringInRND } from 'utils/images';
import AdhdIntro from './components/AdhdIntro';
import AnswerBasedImages from './components/AnswerBasedImages';
import AnswerBased from './components/AnswerBased';
import Statistics from './components/Statistics';
import Timeline from './components/Timeline';
import AnswerBasedStatic from './components/AnswerBasedStatic';
import BookInsights from './components/BookInsights';
import TextImageCardButton from './components/TextImageCardButton';
import PersonalSummary from './components/PersonalSummary';
import IconChecklist from './components/IconChecklist';
import BookReview from './components/BookReview';
import OurPromise from './components/OurPromise';
import OurPromiseHorizontal from '../checkout/components/OurPromiseHorizontal';
import ReviewsCardsList from 'components/ReviewsCardList';
import BlackFridayBannerCheckout from 'components/BlackFridayBannerCheckout';
import BlackFridayCountdown from 'components/BlackFridayCountdown';
import { updateSelectedPlansOptions } from 'state/user/effects';
import WeightLossSummary from './components/WeightLossSummary';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  background: #804da1;
  padding: 0.625rem 1.5rem;
  min-width: 10.22rem;
  max-width: 15rem;
  @media ${mobile} {
    min-width: 7.06rem;
  }
`;

const Separator = styled(SeparatorIcon)`
  width: 100vw;
`;

const EvenList = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media ${mobile} {
    flex-direction: column;

    div:nth-child(1n):not(last-child) {
      margin-bottom: 1.66rem;
    }
  }
`;

const StartNow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
`;

const Results: FC<RouteComponentProps> = ({ location }) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const config = useSelector((state: AppState) => state.config);
  const user = useSelector((state: AppState) => state.user);

  const variant = config?.variant;
  const { countdownDiffInMS } = useFirstVisitDate();
  const selectedPlansOptions = useSelector(
    (state: AppState) => state.user.selected_plans_options,
  );

  const quizData = useQuizData('results');

  const quiz = new URLSearchParams(location?.search).get('qz') ?? QuizType.Main;

  const { goToEmail, goToCheckout } = useRouter();

  const { isMobile } = useQuery();

  const dispatch = useDispatch();

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  const handleContinue = () => {
    if (location?.pathname) {
      Tracking.trackCTAButton(location.pathname);
    }
    if (variant === 'email-first') {
      return goToCheckout({ discount: false });
    }
    goToEmail();
  };

  useEffect(() => {
    if (quiz === 'main-lc') {
      dispatch(
        updateSelectedPlansOptions({
          ...selectedPlansOptions,
          tAndC: false,
        }),
      );
    }
  }, []);

  const renderSection = (item: any, index: number) => {
    const key = `${item.type}-${index}`;

    switch (item.type) {
      default: {
        return <React.Fragment key={key} />;
      }
      case 'basedOnMultiple': {
        return (
          <React.Fragment key={key}>
            <AnswerImages item={item} onClick={handleContinue} />
          </React.Fragment>
        );
      }
      case 'answerBased': {
        return (
          <React.Fragment key={key}>
            <AnswerBased {...item} onClick={handleContinue} />
          </React.Fragment>
        );
      }
      case 'section1': {
        return (
          <React.Fragment key={key}>
            <AnswerResults onClick={handleContinue} {...item} />
            {!isMobile && <Separator />}
          </React.Fragment>
        );
      }
      case 'caseStudiesSection': {
        return (
          <CaseStudies key={key} onStartBtnClick={handleContinue} {...item} />
        );
      }
      case 'appFeaturesSection': {
        return <AppFeatures key={key} {...item} />;
      }
      case 'section2': {
        return (
          <React.Fragment key={key}>
            <MembersStatistics {...item} />
            <Separator />
          </React.Fragment>
        );
      }
      case 'consultation': {
        return <Consultation item={item} onClick={handleContinue} />;
      }
      case 'section3': {
        return (
          <React.Fragment key={key}>
            <Description
              title={item.title}
              onClick={handleContinue}
              mobileSmTitle
              startBtnTitle={item?.startBtnTitle}
            >
              <EvenList>
                {item.list?.map((item: string, index: number) => (
                  <CheckItem key={`description-1-${index}`}>{item}</CheckItem>
                ))}
              </EvenList>
            </Description>
            <Separator />
          </React.Fragment>
        );
      }
      case 'section4': {
        return (
          <Description key={key} title={item.title} subtitle={item.subtitle}>
            <IconList data={item.list} />
          </Description>
        );
      }
      case 'section5': {
        return <AppActions key={key} {...item} />;
      }
      case 'section6': {
        return (
          <Description
            key={key}
            title={item.title}
            subtitle={item.subtitle}
            mobileCenterTitle
            onClick={handleContinue}
            startBtnTitle={item?.startBtnTitle}
          >
            <HorizontalList data={item.list} />
          </Description>
        );
      }
      case 'section7': {
        return <Help key={key} title={item.title} list={item.list} />;
      }
      case 'section8': {
        return <Promise key={key} {...item} onClick={handleContinue} />;
      }
      case 'ourPromise': {
        return <OurPromise key={key} {...item} onClick={handleContinue} />;
      }
      case 'ourPromiseHorizontal': {
        return (
          <OurPromiseHorizontal key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'scientificEvidenceSection': {
        return <BannerSection key={key} {...item} />;
      }
      case 'sliderBenefits': {
        return <SliderBenefits key={key} {...item} onClick={handleContinue} />;
      }
      case 'sliderGraph': {
        return <SliderGraph key={key} {...item} />;
      }
      case 'appBenefitsSection': {
        return (
          <Container key={key} top={4} bottom={4}>
            <CheckoutDescription {...item} onClick={handleContinue} />
          </Container>
        );
      }
      case 'reviewsSection': {
        return <Reviews {...item} key={key} onClick={handleContinue} />;
      }
      case 'reviewsCardsListSection': {
        return (
          <Container key={key} top={4} bottom={4}>
            <ReviewsCardsList {...item} />
          </Container>
        );
      }
      case 'imageSection': {
        return <ImageSection key={key} {...item} />;
      }
      case 'graphAndTypeSection': {
        return <GraphAndType key={key} {...item} onClick={handleContinue} />;
      }
      case 'adhdIntro': {
        return <AdhdIntro key={key} {...item} onClick={handleContinue} />;
      }
      case 'answerBasedImages': {
        return (
          <AnswerBasedImages key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'answerBasedStatic': {
        return (
          <AnswerBasedStatic key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'statistics': {
        return <Statistics key={key} {...item} onClick={handleContinue} />;
      }
      case 'timeline': {
        return <Timeline key={key} {...item} onClick={handleContinue} />;
      }
      case 'bookInsights': {
        return <BookInsights key={key} {...item} onClick={handleContinue} />;
      }
      case 'text_image_card_button': {
        return (
          <TextImageCardButton key={key} {...item} onClick={handleContinue} />
        );
      }
      case 'personal_summary': {
        return <PersonalSummary key={key} {...item} />;
      }
      case 'icon_checklist': {
        return <IconChecklist key={key} {...item} onClick={handleContinue} />;
      }
      case 'book_review': {
        return <BookReview key={key} {...item} onClick={handleContinue} />;
      }
      case 'bfBannerCheckout': {
        return <BlackFridayBannerCheckout key={key} {...item} />;
      }
      case 'bfCountdown': {
        return (
          <BlackFridayCountdown
            key={key}
            {...item}
            countdownDiffInMS={countdownDiffInMS}
            onClick={handleContinue}
          />
        );
      }
      case 'weight-loss-summary': {
        return (
          <WeightLossSummary key={key} onClick={handleContinue} {...item} />
        );
      }
    }
  };

  if (!quizData) {
    return null;
  }

  return (
    <>
      <Seo />
      <Header
        logoVariant={quizData?.navBarBtnTitle ? 'left' : 'center'}
        RightComponent={
          quizData?.navBarBtnTitle ? (
            <Button
              onClick={handleContinue}
              fontSize={isMobile ? 0.75 : undefined}
              minHeight={isMobile ? 2.5 : undefined}
            >
              {quizData?.navBarBtnTitle}
            </Button>
          ) : null
        }
      />
      {/* <ContentLoader imagesToWait={searchForStringInRND(quizData.sections)} /> */}
      {!!quizData?.headlineTitle && (
        <Headline
          bodyText={quizData.headlineTitle}
          bgColor={quizData.headlineBgColor}
        />
      )}

      {quizData?.sections?.map(renderSection)}
      {/* <StartNow ref={bottomRef}>
        <StartNowButton onClick={handleContinue}>
          {
            quizData?.sections?.find(({ type }) => type === 'section8')
              ?.startBtnTitle
          }
        </StartNowButton>
      </StartNow> */}
    </>
  );
};

export default Results;
