import React, { FC } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import StartNowButton from './StartNowButton';
import { mobile } from 'styles/breakpoints';

interface BookInsightsProps {
  title: string;
  cards: {
    title: string;
    subTitle: string;
  }[];
  onClick: () => void;
}

const BookInsights: FC<BookInsightsProps> = ({ title, cards, onClick }) => (
  <>
    <Container>
      <Title>{title}</Title>
      <CardContainer>
        {cards.map((item, index) => (
          <Card key={index}>
            <CardTitle>{item.title}</CardTitle>
            <CardSubtitle>{item.subTitle}</CardSubtitle>
          </Card>
        ))}
      </CardContainer>
      <Button onClick={onClick}>Get your book</Button>
    </Container>
  </>
);

export default BookInsights;

const Button = styled(StartNowButton)`
  margin-top: 24px;
  width: 560px;
  height: 56px;
  background-color: #804da1;
  @media ${mobile} {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${mobile} {
    flex-wrap: no-wrap;
    flex-direction: column;
    width: 100%;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  width: 272px;
  height: 140px;
  background: rgba(255, 135, 90, 0.1);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin: 8px;
  @media ${mobile} {
    width: 100%;
    margin: 8px 0;
  }
`;

const CardTitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  padding: 16px 16px 0;
  text-align: center;

  color: #ff5c5c;
`;

const CardSubtitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  padding: 0 16px 0;
  text-align: center;

  color: #212529;

  @media ${mobile} {
    width: 311px;
  }
`;

const Title = styled(Text)`
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 130%;
  letter-spacing: -0.036em;
  text-align: center;

  width: 265px;
  padding-bottom: 26px;

  @media ${mobile} {
    font-size: 1.5rem;
    width: 230px;
  }
`;

const Container = styled.div`
  width: 576px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  @media ${mobile} {
    width: unset;
    padding: 0 16px;
  }
`;
