import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface ContainerProps {
  widthVariant?: 'default' | 'small' | 'none';
  top?: number;
  bottom?: number;
  flex?: boolean;
}

const Container = styled.div<ContainerProps>`
  max-width: ${({ widthVariant }) =>
    widthVariant === 'small'
      ? '48rem'
      : widthVariant === 'none'
      ? 'none'
      : '1180px'};
  margin: ${({ top = 'auto', bottom = 'auto' }) =>
    `${top}rem auto ${bottom}rem auto`};

  @media ${tablet} {
    margin: ${({ top = 'auto', bottom = 'auto' }) =>
      `${typeof top === 'number' ? top / 2 : top}rem 1rem ${
        typeof bottom === 'number' ? bottom / 2 : bottom
      }rem 1rem`};
  }

  ${({ flex }) =>
    flex
      ? `
      display: flex;
      flex-direction: column;
      align-items: center;
    `
      : ''}
`;

export default Container;
