import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import ContentContainer from './ContentContainer';
import { mobile, useQuery } from 'styles/breakpoints';
import StartNowButton from './StartNowButton';

interface PromiseProps {
  title: string;
  image: string;
  imageMobile: string;
  alt: string;
  buttonText: string;
  onClick: () => void;
}

const Title = styled(Text).attrs({
  color: 'dark100',
})`
  margin-bottom: 3rem;
  text-align: center;

  @media ${mobile} {
    font-size: 1.625rem;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 100%;
  height: 1012px;

  @media ${mobile} {
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  }
`;

const Button = styled(StartNowButton)`
  background: #804da1;
  border-radius: 100px;
  width: 560px;
  height: 56px;
  margin: 0 auto;
  margin-top: 24px;
  @media ${mobile} {
    width: 100%;
  }
`;

const Promise: FC<PromiseProps> = ({
  title,
  image,
  imageMobile,
  alt,
  buttonText,
  onClick,
}) => {
  const { isMobile } = useQuery();
  return (
    <ContentContainer>
      <Title type={isMobile ? 'countS' : 'count'}>{title}</Title>
      <StyledDynamicImage src={image} mobile_src={imageMobile} alt={alt} />
      <Button onClick={onClick}>{buttonText}</Button>
    </ContentContainer>
  );
};

export default Promise;
