import { DynamicImage } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';

interface IconChecklistProps {
  image: string;
  title: string;
  listItems: {
    icon: string;
    text: string;
  }[];
}

const IconChecklist: FC<IconChecklistProps> = ({ image, title, listItems }) => (
  <>
    <Container>
      <Card>
        <CardIcon alt="icon" src={image} />
        <CardTitle>{title}</CardTitle>

        <CheckListContainer>
          {listItems.map((item, index) => (
            <Item key={index}>
              <Icon alt="check" src={item.icon} />
              <Text>{item.text}</Text>
            </Item>
          ))}
        </CheckListContainer>
      </Card>
    </Container>
  </>
);

export default IconChecklist;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @media ${mobile} {
    padding: 0 16px;
  }
`;
const Card = styled.div`
  width: 560px;
  height: 416px;
  background: rgba(128, 77, 161, 0.08);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  @media ${mobile} {
    width: 100%;
    padding: 24px;
  }
`;

const CardIcon = styled(DynamicImage)`
  width: 188px;
  height: 170.28px;
`;
const CardTitle = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 28px;
  color: #212529;
  width: 322px;
  text-align: center;
  margin: 16px 0;
  @media ${mobile} {
    font-size: 1rem;
    width: 295px;
  }
`;

const CheckListContainer = styled.div`
  width: 100%;
  @media ${mobile} {
    width: unset;
  }
`;

const Item = styled.div`
  display: flex;
  padding-bottom: 10px;
  align-items: center;
`;

const Icon = styled(DynamicImage)`
  height: 26px;
  width: 26px;
`;

const Text = styled.p`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;

  color: #212529;
  margin-left: 8px;
  @media ${mobile} {
    font-size: 0.875rem;
  }
`;
